.about-section {
  background-color: var(--dark-800);



text-align: center;
  
}



.h-100 {
    
  min-height: 100vh;
}


.about-img,
.about-text{


    width:50%;
    
}

.about-text h1 span{

    position: relative;
    color:var(--color-2);
 

}


.about-text h1{


    font-size: 50px;
    color: var(--color-7);
    text-transform: uppercase;
    line-height:1.5;
    padding-top:90px;
}



.about-img-inner{
    position: relative;
    width:350px;
    margin:0 auto;
}
.about-img-inner img{
    animation: zoom 7s linear infinite;
    width:100%
  
}

.fun-facts-img img{
margin: 0 auto;
}


.btn{
  
    display: inline-block;
    padding: 10px 30px;
    border-radius:30px;
    text-transform: capitalize;
    font-weight: 900px;
    border:1px solid transparent;
    transition:all 0.3s ease;
}

.btn-1{
    background-color:var(--color-7);
    color: var(--white-900);
    margin-bottom: 90px;
}




.btn-1:hover{
    background-color:transparent;
    border-color: var(--color-7);
    color: var(--color-7)


}

.about-skills{

    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    animation: spin01 7s linear infinite;
    

}

  @keyframes spin01 {
    0%,100%{
        transform:scale(1);
    }
    50%{
        transform:scale(0.6);
    }
} 

@keyframes zoom {
    0%,100%{
        transform:scale(0.4);
    }
    50%{
        transform:scale(0.7);
    }
} 



.about-skills-itens{

    position:absolute;
    height: 80px;
    width: 80px;
    border-radius:50% ;
    display:flex;
    align-items: center;
    justify-content: center;
    text-transform:uppercase;
    font-size: 16px;
   color: var(--white-900);

}

.about-skills-itens:nth-child(1){
    right:38%;
    top:-40px;
    background-color: var(--color-7);
    
}
.about-skills-itens:nth-child(2){
    left:-40px;
    top:30%;
    background-color: var(--color-7);

}
.about-skills-itens:nth-child(3){
    right:-50px;
    top:30%;
    background-color: var(--color-7);
    
}

.about-skills-itens:nth-child(4){
    right:5%;
    bottom:-30px;
    background-color: var(--color-7);
    
    
}
.about-skills-itens:nth-child(5){
 
    left:5%;
    bottom:-30px;
    background-color: var(--color-7);
    
    
}

.about-skills-itens:nth-child(6){
 
    left:90%;
    bottom:75px;
    background-color: var(--color-2);
    
    
}

.about-skills-itens:nth-child(7){
 
    left:40%;
    bottom:-60px;
    background-color: var(--color-2);
    
    
}


.about-skills-itens:nth-child(8){
 
    right:90%;
    bottom:75px;
    background-color: var(--color-2);
    
    
}

.about-skills-itens:nth-child(9){
 
    right:75%;
    top:10px;
    background-color: var(--color-2);
    
    
}

.about-skills-itens:nth-child(10){
 
    left:75%;
    top:10px;
    background-color: var(--color-2);
    
    
}

@media (max-width:991px){

    .about-text h1{
        width: 100%;
       
  
font-size:40px;
        padding-top:200px;
        margin-left:250px;
      
    }
.about-img{
    padding-bottom: 60px;
    

}
   
    .btn-1{
        width: 100%;
        margin-left: 200px;


    }
    .about-img,.about-tex{
        padding-bottom: 10px;
        width: 100%

    }
   
  
 
    
  }

  @media (max-width:767px){
      
    .about-text h1{
        font-size:30px;

    }
      .about-text h1 span{
          
   white-space: nowrap;
          width: 100%;
      }
  
    

}

@media (max-width:575px){
      
    .about-text h1{
        margin-top:-40px;
        margin-left: 30%;
        font-size:25px;
        padding-bottom: 40px;

    }
      .about-text h1 span{
        white-space: nowrap;
        
          margin-left: 40px;
          
      }

      .btn-1{
          margin-top: -0px;
        width: 100%;
        margin-left: 50px;
        margin-bottom: 40px;


    }

    .about-img-inner{
        width:220px;
    }

  

    .about-skills-itens:nth-child(1){
        position:relative;
        left:30%;
        top:-30px;
        width: 40%;
        height: 25%;
      
        
    }
    .about-skills-itens:nth-child(2){
        /* HTML */
        left:-55px;
        top:30%;
        width: 40%;
        height: 25%;
    
    }
    .about-skills-itens:nth-child(3){
        /* CSS */
        right:-50px;
        top:30%;
        width: 40%;
        height: 25%;
        
    }
    
    .about-skills-itens:nth-child(4){
        /* Python */
        right:-15%;
        bottom:-20px;
        width: 40%;
        height: 25%;
        
        
    }
    .about-skills-itens:nth-child(5){
     /* NoSQL */
        left:-15%;
        bottom:-20px;
        width: 40%;
        height: 25%;
        
        
    }
    
    .about-skills-itens:nth-child(6){
      /* MySQL */
        left:80%;
        bottom:40px;
        width: 40%;
        height: 25%;
        
        
    }
    
    .about-skills-itens:nth-child(7){
       /* Solidity */
        left:30%;
        bottom:-40px;
        width: 40%;
        height: 25%;
        
        
    }
    
    
    .about-skills-itens:nth-child(8){
     /* JAVA */
        right:80%;
        bottom:40px;
        width: 40%;
        height: 25%;
        
        
    }
    
    .about-skills-itens:nth-child(9){
     /* JS */
        right:75%;
        top:10px;
        width: 40%;
        height: 25%;
        
        
    }
    
    .about-skills-itens:nth-child(10){
     /* TS */
        left:75%;
        top:10px;
        width: 40%;
        height: 25%;
        
        
    }


 
    .fun-facts-item {
        
        justify-content:center;
    }

    .fun-facts-items{
        width: 100%;
    }

    .fun-facts-section{
        margin:0 auto;

    }
  
    
}



