.section {
    background-color: var(--dark-700);
    padding-top: 5px;
}

p {
    line-height: 30px;
    padding-bottom: 20px;
    font-size: 22px;
    text-align: justify; /* Ensures better readability */
    margin: 0 20px;
    text-justify: inter-word; /* Helps with spacing between words */
}

/* Slower animation for better user experience */
.animated {
    animation-duration: 2.5s !important;
}

/* Media Queries for Mobile Readability */
@media (max-width: 768px) {
    p {
        font-size: 18px; /* Slightly smaller font size for mobile */
        margin: 0 15px;
    }
}

@media (max-width: 480px) {
    p {
        font-size: 18px; /* Optimized for small screens */
        margin: 0 10px;
    }

    .fun-facts-img {
        display: flex;
        justify-content: center; /* Centers horizontally */
        align-items: center; /* Centers vertically */
        width: 100%;
        margin-bottom: 20px;
    }
    
    .fun-facts-img img {
        max-width: 100%; /* Ensures the image is responsive */
        height: auto;
        display: block;
    }
}
