/* General Section Styles */
.fun-facts-section {
  background-color: var(--dark-700);
  padding: 20px 0 60px;
}

/* Image Alignment */
.fun-facts-img {
  display: flex;
  justify-content: center;
}

.fun-facts-img img {
  max-width: 400px;
  width: 100%;
}

.fun-facts-img,
.fun-facts-items {
  width: 50%;
}

/* Text Formatting */
.fun-facts-item p a {
  color: var(--white-800);
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

.fun-facts-item p a:hover {
  width: 150px;
  background-color: var(--white-900);
  color: var(--color-2);
  padding: 5px;
}

.fun-facts-item h3 {
  padding-bottom: 10px;
  font-size: 25px;
  color: var(--white-900);
  text-transform: capitalize;
}

/* Project Card Layout */
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-content: center; /* Center grid items */
  align-items: center;
}

/* Individual Project Card */
.project-card {
  width: 100%;
  max-width: 300px; /* Ensuring uniform card size */
  height: 320px; /* Adjust height for consistency */
  background: var(--dark-600);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto; /* Center each card */
}

.project-card:hover {
  transform: scale(1.05);
}

/* Project Card Links */
.project-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

/* Project Image */
.project-image {
  width: 100%;
  height: 200px; /* Consistent height */
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Project Card Description */
figcaption {
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  width: 100%;
  height: 100px;
  text-align: center;
}

figcaption h3 {
  margin: 5px 0;
  font-size: 18px;
}

figcaption p {
  font-size: 14px;
  margin: 0;
}

figcaption span {
  display: block;
  margin-top: 5px;
  font-weight: bold;
  color: var(--color-7);
}

/* Mobile-Friendly Adjustments */
@media (max-width: 768px) {
  .cards {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
  }

  .project-card {
    width: 90%; /* Responsive width */
    max-width: 320px;
  }
}

@media (max-width: 480px) {
  .fun-facts-item h3 {
    font-size: 20px;
  }

  figcaption h3 {
    font-size: 16px;
  }

  figcaption p {
    font-size: 12px;
  }
}
