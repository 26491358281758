:root {
  --color-1: #eaa023;
  --color-2: #e91263;
  --color-3: #e75c26;
  --color-4: #006ac5;
  --color-5: #0fa7ff;
  --color-6: #24caac;
  --color-7: #0caa68;
  --color-8: #222335;
  --color-9:#000;
  --color-10:#fbecd5;
  --color-11:#f5f5f5;
   --color-12:#8d5a38;
  --dark-800: #24292d;
  --dark-700: #2f363e;
  --white-900: #ffffff;
  --white-800: rgba(255, 255, 255, 0.8);
  -shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}


html {
  scroll-behavior: smooth;
}

:target {
  scroll-margin-top: 4em;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  scroll-behavior: smooth;
}

::before::after {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  line-height: 1.5;
  font: weight 300px;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

.container {
  max-width: 1170px;

  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

